.services-group {
    display: flow;
    overflow-x: auto;
    gap: 10px;
    padding: 20px 0;
    width: fit-content;
    margin: auto;
    filter: contrast(1.75);
    max-width: 92%;
}

.services-title {
    color: #b1e89e;
    font-size: 2.5em;
    font-weight: bold;
    text-align: center;
}