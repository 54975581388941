h4 {
    color: white;
    font-size: 32px;
    font-weight: lighter;
}

.main-logo {
    max-width: 100px;
    height: fit-content;
    margin: auto 16px;
}