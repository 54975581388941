.product-logo {
    max-width: 150px;
    height: 150px;
}

.product-description {
    max-width: 80%;
    margin: auto;
}

.product-group {
    display: inline;
    margin: 0px 24px;
    max-width: 80%;
}

.product-name {
    color: #b1e89e;
    font-size: xx-large;
    margin: auto 0px;
    padding: 32px;
}

.product {
    margin: auto;
    width: fit-content;
}