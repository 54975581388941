.image-scroller {
    display: flow;
    overflow-x: auto;
    gap: 10px;
    padding: 20px 0;
    width: fit-content;
    margin: auto;
    filter: contrast(1.75);
    max-width: 92%;
}
  
.image-scroller img {
    max-height: 100px; /* Adjust based on your preference */
    border-radius: 10px; /* Optional, for styling */
    margin: 36px;
    max-width: 280px;
}
